import { Box } from "@mui/system";
import { Colors } from "../utils/Colors";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { handleDownloadExcel } from "../utils/common";
import { Download } from "@mui/icons-material";

export const DetailByPump = ({ servicio, institucion, bombas }) => {
  const [showPumps, setShowPumps] = useState(false);
  return (
    <Box
      padding="0.2rem .5rem"
      sx={{
        justifyContent: "space-between",
        border: "1px solid " + Colors.primary,
        borderRadius: "5px",
        width: "100%",
      }}
    >
      <Box
        display="flex"
        padding="0.2rem .5rem"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          onClick={() => setShowPumps(!showPumps)}
          sx={{
            cursor: "pointer",
          }}
        >
          <strong>
            {servicio && servicio !== "null" ? servicio : "Sin servicio"}:{" "}
            {bombas.length}
          </strong>
        </Typography>
        <IconButton
          title={`Descargar ${servicio}`}
          onClick={(e) =>
            handleDownloadExcel(bombas, `${institucion} - ${servicio}`)
          }
          sx={{
            color: Colors.primary,
          }}
        >
          <Download />
        </IconButton>
      </Box>

      {showPumps && (
        <Box
          display="flex"
          flexDirection="column"
          gap=".5rem"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {bombas.map((bomba) => (
            <Box
              key={bomba.id}
              display="flex"
              gap="1rem"
              padding=".2rem .5rem"
              flexWrap="wrap"
              sx={{
                width: "100%",
                borderBottom: "1px solid " + Colors.primary,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle2">
                <strong>SN: </strong>
                {bomba.serie}
              </Typography>
              <Typography variant="subtitle2">
                <strong>QR: </strong>
                {bomba.qr}
              </Typography>
              <Typography variant="subtitle2">
                <strong>M: </strong>
                {bomba.modelo}
              </Typography>
              <Typography variant="subtitle2">
                <strong>T: </strong>
                {bomba.tipo}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
