export const Constants = {
  validHeaders: ["serie", "qr", "servicio", "modelo", "tipo"],
};

//export const baseUrl = "/api/"
export const baseUrl = "https://srv586737.hstgr.cloud/api/";

//tipos
export const tipos = [
  { id: 1, tipo: "INFUSOR" },
  { id: 2, tipo: "PERFUSOR" },
  { id: 3, tipo: "FUENTE US" },
  { id: 4, tipo: "FUENTE EU" },
  { id: 5, tipo: "NO APLICA" },
];

export const modelos = [
  { id: 1, modelo: "SPACE" },
  { id: 2, modelo: "SPACE PLUS" },
  { id: 3, modelo: "COMPACT" },
  { id: 4, modelo: "COMPACT PLUS" },
  { id: 5, modelo: "ENTEROPORT PLUS" },
  { id: 6, modelo: "FMS" },
  { id: 7, modelo: "FM" },
];
