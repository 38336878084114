import { Box, Typography } from "@mui/material";
import { Colors } from "../utils/Colors";
import { Progreso } from "./Avance";
import { DetailByServices } from "./historial/DetailByServices";

export const AvancePorInstitucion = ({
  institucion,
  totalBombas,
  bombasInventariadas,
}) => {
  const isInventariada = (bomba) =>
    bombasInventariadas.some(
      (b) =>
        b.serie === bomba.serie &&
        b.modelo === bomba.modelo &&
        b.tipo === bomba.tipo,
    );

  const bombasRestantes = totalBombas.filter((bomba) => !isInventariada(bomba));

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        borderRadius: ".5rem",
        border: "2px solid " + Colors.primary,
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding=".5rem"
        sx={{
          width: "100%",
          backgroundColor: Colors.primary,
          color: "white",
        }}
      >
        <Typography variant="subtitle2">{institucion}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding="1rem 0" gap=".5rem">
        <DetailByServices
          bombas={totalBombas}
          institucion={institucion}
          label="Total de bombas"
        />
        <DetailByServices
          bombas={bombasInventariadas}
          institucion={institucion}
          label="Bombas inventariadas"
        />
        <DetailByServices
          bombas={bombasRestantes}
          institucion={institucion}
          label="Bombas restantes"
        />

        <Progreso
          avance={(bombasInventariadas.length * 100) / totalBombas.length}
        />
      </Box>
    </Box>
  );
};
