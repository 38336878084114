import {
  organizarDatosPorCategoria,
  sortObjectByArrayLength,
} from "../utils/common";

import { AvancePorInstitucion } from "./AvancePorInstitucion";

export const PumpsByInstitucion = ({ bombas, bombasInventariadas }) => {
  const bombasSorted = sortObjectByArrayLength(bombas);
  const bombasOrganizadas = organizarDatosPorCategoria(
    bombasInventariadas,
    "institucion",
  );

  return Object.keys(bombasSorted).map((institucion, index) => (
    <AvancePorInstitucion
      key={index}
      totalBombas={bombasSorted[institucion]}
      bombasInventariadas={
        bombasOrganizadas[institucion] ? bombasOrganizadas[institucion] : []
      }
      institucion={institucion}
    />
  ));
};
