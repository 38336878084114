import { Box } from "@mui/system";
import { Colors } from "../../utils/Colors";
import { IconButton, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import {
  handleDownloadExcel,
  organizarDatosPorCategoria,
  sortObjectByArrayLength,
} from "../../utils/common";
import { useState } from "react";
import { DetailByPump } from "../DetailByPump";

export const DetailByServices = ({ bombas, institucion, label }) => {
  const [showServices, setShowServices] = useState(false);

  const pumpsOrderedByService = organizarDatosPorCategoria(bombas, "servicio");
  const orderedPumpsByCount = sortObjectByArrayLength(pumpsOrderedByService);

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="0.2rem .5rem"
      sx={{
        width: "100%",
        borderBottom: "1px solid " + Colors.primary,
      }}
    >
      <Box
        display="flex"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle2"
          onClick={() => setShowServices(!showServices)}
          sx={{ cursor: "pointer" }}
        >
          <strong>{label}:</strong> {bombas?.length || 0}
        </Typography>

        <IconButton
          title={`Descargar ${label}`}
          onClick={(e) =>
            handleDownloadExcel(bombas, `${institucion} - ${label}`)
          }
          sx={{
            color: Colors.primary,
          }}
        >
          <Download />
        </IconButton>
      </Box>

      {showServices && (
        <Box
          display="flex"
          gap=".5rem"
          flexDirection="column"
          padding=".5rem 0"
        >
          {Object.keys(orderedPumpsByCount).map((servicio, index) => (
            <DetailByPump
              key={index}
              servicio={servicio}
              institucion={institucion}
              bombas={orderedPumpsByCount[servicio]}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
