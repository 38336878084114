import { Box, Typography } from "@mui/material";
import { Progreso } from "./Avance";

export const DetailByInstitucion = ({
  institucion,
  dataSortedByInstitucion,
  cantidadBombasPorInstitucion,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="0.2rem .4rem"
        gap="1rem"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          gap="1rem"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Typography variant="subtitle2">{institucion}</Typography>
          <Typography variant="subtitle2" title="Total de bombas">
            <strong>{cantidadBombasPorInstitucion}</strong>
          </Typography>
        </Box>

        <Box
          display="flex"
          gap="1rem"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Progreso
            avance={
              (dataSortedByInstitucion.length * 100) /
              cantidadBombasPorInstitucion
            }
          />
          <Typography title="Bombas inventariadas" variant="subtitle2">
            <strong>({dataSortedByInstitucion.length})</strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
