import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { organizarDatosPorCategoria } from "../../utils/common";
import { MyItemN1 } from "./MyItemN1";
import CategorySelector from "./CategorySelector";
import { PumpsByInstitucion } from "../PumpsByInstitucion";

const ListView = ({
  user,
  data,
  handleAutoSearch,
  showOnlyOwnInventories,
  setShowOnlyOwnInventories,
  inventariosEditados,
}) => {
  const [category, setCategory] = useState("fecha_actualizacion");

  const [viewAll, setViewAll] = useState(false);
  // console.log("data", data);
  // console.log("inventarioseditados", inventariosEditados);
  //
  //
  let datosF;
  if (category === "institucion") {
    datosF = data.allInventories;
  } else {
    datosF = showOnlyOwnInventories
      ? data.inventariosPropios
      : data.allInventories;
  }
  const datosOrganizados = organizarDatosPorCategoria(datosF, category);

  let datosLimitados;

  if (!viewAll) {
    datosLimitados = Object.fromEntries(
      Object.entries(datosOrganizados).slice(0, 1),
    );
  } else {
    datosLimitados = datosOrganizados;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      sx={{
        width: "100%",
        alignItems: "center",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <CategorySelector setCategoria={setCategory} />
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          padding="0 1rem"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyOwnInventories}
                onChange={(e) => setShowOnlyOwnInventories(e.target.checked)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Mostrar solo mis inventarios"
          />

          {Object.entries(datosOrganizados).length > 1 && (
            <Button variant="contained" onClick={(e) => setViewAll(!viewAll)}>
              {!viewAll ? (
                <b>Ver todo (+{Object.entries(datosOrganizados).length - 1})</b>
              ) : (
                <b>Ver menos</b>
              )}
            </Button>
          )}
        </Box>
      </Box>

      {category === "fecha_actualizacion" ? (
        Object.keys(datosLimitados).map((invKey, index) => (
          <MyItemN1
            key={index}
            fecha={invKey}
            inventarios={datosLimitados[invKey]}
            handleAutoSearch={handleAutoSearch}
            user={user}
          />
        ))
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          sx={{ width: "100%", padding: "0 .5rem" }}
        >
          <PumpsByInstitucion
            bombasInventariadas={
              showOnlyOwnInventories
                ? inventariosEditados.filter(
                    (inv) => inv.responsable_id === user.id,
                  )
                : inventariosEditados
            }
            bombas={datosLimitados}
          />
        </Box>
      )}
    </Box>
  );
};
export default ListView;
